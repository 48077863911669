import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import styled from "styled-components";

const ImmokreditStudie202122 = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Graz ist Österreichs begehrtester Immobilien Hotspot" showCalc={false} />
            <Article>
                <p>
                    Immokredit Studie 2021/22 von{" "}
                    <Link to="/" target="_blank" rel="noreferrer noopener">
                        miracl.at
                    </Link>{" "}
                    und{" "}
                    <a href="https://www.datascience-service.at/" target="_blank" rel="noreferrer noopener">
                        DataScience Service GmbH
                    </a>
                </p>
                <p>
                    <strong>
                        Die Immokredit Studie 2021/22 der österreichischen Immobilienkredit-Plattform{" "}
                        <Link to="/" target="_blank" rel="noreferrer noopener">
                            miracl.at
                        </Link>{" "}
                        und dem führenden Anbieter automatisierter Immobilienbewertungssoftware in Österreich, der{" "}
                        <a href="https://www.datascience-service.at/" target="_blank" rel="noreferrer noopener">
                            DataScience Service GmbH
                        </a>
                        , gibt tiefe Einblicke in den heimischen Immobilienmarkt. Begehrtester Ort für Immobilienkäufe
                        war zwischen Q1 2021 und Q1 2022 Graz, dicht gefolgt von Innsbruck-Land auf Platz 2 und Baden
                        auf Platz 3.
                    </strong>
                </p>
                <hr />

                <p>
                    Wien, 11.07.2022. Immobilienkäufe stehen hoch im Kurs. Viele Österreicher:innen sehnen sich nach
                    eigenen vier Wänden oder wollen in “Betongold” investieren. Derzeit beliebtester Ort dafür: die
                    steirisches Landeshauptstadt Graz. Hier wurden zwischen dem ersten Quartal 2021 und dem ersten
                    Quartal 2022 am meisten Immobilien ge- und verkauft. Ebenfalls in den Top 3: Innsbruck (Land) auf
                    Platz 2 und Baden auf Platz 3. Wien wurde für die Studie in seine 23 Bezirke aufgeteilt. Dabei
                    erleben vor allem die Außenbezirke einen richtigen Boom. Donaustadt (22. Bezirk) und Floridsdorf
                    (21. Bezirk) haben es sogar unter die Top 20 geschafft, was daran liegen mag, dass die
                    Quadratmeterpreise im innerstädtischen Gebiet der Bundeshauptstadt in den letzten Jahren stark
                    gestiegen sind und die äußeren Bezirke im Vergleich noch leistbarer scheinen
                </p>
                <StaticImage
                    src="../../assets/images/immokredit-studie-2021-22/immokredit-studie-2021-22-img-1.png"
                    alt="Wien, 11.07.2022. Immobilienkäufe stehen hoch im Kurs"
                />
                <hr />

                <h2>Klagenfurt innerhalb eines Jahres fast ein Fünftel teurer</h2>
                <p>
                    Wer sich bereits 2021 eine Immobilie in Klagenfurt gekauft hat, darf sich freuen. Denn innerhalb nur
                    eines Jahres (Vergleichszeitraum wieder Q1 2021 – Q1 2022) stieg der Quadratmeterpreis für ein
                    gleichwertiges Objekt in der Stadt rund um den Lindwurm im Schnitt um 19,6%.
                </p>
                <p>
                    Damit erzielt Klagenfurt die höchste Preissteigerung innerhalb Österreichs. Auf Platz 2 und 3 folgen
                    Zell am See in Salzburg und Innsbruck (Land).
                </p>
                <StaticImage
                    src="../../assets/images/immokredit-studie-2021-22/immokredit-studie-2021-22-img-2.png"
                    alt="Klagenfurt innerhalb eines Jahres fast ein Fünftel teurer"
                />
                <hr />

                <h2>Nominalzins innerhalb eines halben Jahres mehr als verdoppelt</h2>
                <p>
                    Auch die Zinsen steigen. Die aktuelle Wirtschaftslage scheint bereits Auswirkungen auf den
                    Nominalzins zu haben. Dieser hat sich innerhalb eines halben Jahres (Jänner 2022 – Juni 2022) von
                    1,27% auf 2,8% mehr als verdoppelt. Beispielhaft bedeutet das für eine:n Käufer:in mit Eigenkapital
                    von 70.000 € und einem monatlichen Einkommen von 2.500 €, dass man sich Anfang des Jahres noch
                    leicht 50 qm Wohnraum hätte leisten können, mittlerweile nur mehr 36 qm.
                </p>
                <StaticImage
                    src="../../assets/images/immokredit-studie-2021-22/immokredit-studie-2021-22-img-3.png"
                    alt="Nominalzins innerhalb eines halben Jahres mehr als verdoppelt"
                />
                <hr />

                <h2>Immobilienkauf kann immer sinnvoll sein</h2>
                <p>
                    “Natürlich gibt es viele Optionen wie man sein Geld anlegen kann – von Hedgefonds bis zum Sammeln
                    seltener Weine. Aber eine Immobilie ist nach wie vor eine der beständigsten und sichersten
                    Wertanlagen. Und auch wenn die Zinsen innerhalb der letzten 6 Monate gestiegen sind, ist die
                    aktuelle Zinslage immer noch eine der günstigsten der letzten Jahrzehnte”, so David Savasci, Gründer
                    der Immobilien Kreditvermittlungsplattform miracl.at.
                </p>
                <hr />

                <H3>Anmerkung:</H3>
                <p>
                    Diese Pressemitteilung beinhaltet Ausschnitte der Immokredit Studie 2021/22. Auf Wunsch können gerne
                    weitere Daten und Informationen angefragt werden
                </p>
                <H3>Pressekontakt:</H3>
                <p>
                    <strong>Lukas Kalny</strong>
                </p>
                <p>
                    <a href="mailto:lukas.kalny@miracl.at">lukas.kalny@miracl.at</a>
                </p>
                <p>
                    <a href="tel:+4369917597011">+43 699 17597011</a>
                </p>
                <hr />

                <p>
                    <strong>miracl.at</strong> ist ein Start-Up mit Sitz in Wien, das sich auf Vergleich und Beratung
                    von Wohn- und Baukrediten und deren Vermittlung zu den besten Konditionen spezialisiert hat
                </p>
                <p>
                    <strong>Web:</strong>{" "}
                    <Link to="/" target="_blank" rel="noreferrer noopener">
                        https://miracl.at/
                    </Link>{" "}
                </p>
                <p>
                    <strong>DataScience Service GmbH</strong> mit Sitz in Wien und Salzburg ist Sieger der Kategorie
                    „High Tech“ beim GEWIN Jungunternehmerwettbewerb und hat mittels exzellenter Daten auf
                    wissenschaftlicher Grundlage die Software ImmAzing® - ein Service zur automatisierten
                    Immobilienbewertung für Finanzdienstleister - entwickelt.
                </p>
                <p>
                    <strong>Web:</strong>{" "}
                    <a href="https://www.datascience-service.at/" target="_blank" rel="noreferrer noopener">
                        https://www.datascience-service.at/
                    </a>
                </p>
                <hr />

                <p>
                    Die <strong>Immokredit Studie</strong> ist eine laufende, analytische Beobachtung des heimischen
                    Immobilien- und Kreditmarktes der beiden FinTech Unternehmen <strong>miracl.at</strong> und{" "}
                    <strong>DataScience Service GmbH</strong>. Im Fokus stehen dabei Informationen zum Kaufverhalten der
                    Österreicher:innen, Preis- und Kreditveränderungen. Die Daten, die der Immokredit Studie zugrunde
                    liegen, stammen von grundbürgerlichen Kaufverträgen, die mit den Qualitätsinformationen von
                    Angebotsdaten angereichert und durch die DataScience Service GmbH in Kooperation mit miracl
                    ausgewertet wurden. Der Vergleichszeitraum der Immokredit Studie liegt zwischen Q1 2021 und Q1 2022.
                </p>
            </Article>

            <BreadcrumbList page={"immokreditStudie202122"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immokreditStudie202122"}
                heading={"Immobilienkredit-Rechner – jetzt Kredit berechnen mit miracl."}
            />
        </Layout>
    );
};

const H3 = styled.h3`
    text-decoration: underline;
`;

export default ImmokreditStudie202122;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.immokredit-studie-2021-22"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
